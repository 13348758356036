<template>
  <!-- Main section start -->
  <section class="h-auto md:h-auto lg:h-[115vh]">
      <div class="h-auto md:h-auto lg:h-[115vh] page-bg">
          <div class="p-6">
              <img v-motion-pop-visible src="../assets/MysticBertXRoobetLogo.png" alt="logo">
          </div>
          <div class="flex flex-col lg:flex-row justify-center items-center p-8 lg:p-0 gap-10 lg:gap-24 2xl:mt-8">
              <div class="flex flex-col px-0 lg:px-0">
                  <a href="https://roobet.com/?ref=mysticbert" target="_blank">
                    <img v-motion-slide-visible-left src="../assets/join-roobet.png" class="w-[95%]" alt="sign-up">
                  </a>
              </div>
              <div>
                  <img v-motion-pop-visible src="../assets/roobet-logo.png" alt="roobet-logo">
              </div>
          </div>
      </div>
  </section>
  
  <section class="h-auto lg:-mt-24">
      <div class="flex flex-col px-10 gap-8 lg:gap-14">
          <div class="flex flex-col-reverse lg:flex-row justify-center items-center lg:gap-20">
              <img v-motion-slide-visible-left src="../assets/affiliate.png" alt="affiliate">
              <img v-motion-pop-visible src="../assets/affiliate-img.png" alt="affiliate image">
          </div>
          <div class="flex flex-col lg:flex-row justify-center items-center lg:gap-20">
              <img v-motion-pop-visible src="../assets/deposit-img.png" alt="deposit image">
              <img v-motion-slide-visible-left src="../assets/deposit.png" alt="deposit">
          </div>
      </div>
  </section>

  <LeaderboardSection/>

  <section>
    <div class="flex flex-col justify-center items-center" style="font-family: Black Han Sans, sans-serif;">
        <div class="flex flex-col justify-center items-center px-8">
          <div v-motion-pop-visible class="text-white text-xl lg:text-4xl text-center" style="text-shadow: #FFFFFF 5px 0 15px;">JOIN MYSTICBERT LEADEARBOARD BY FOLLOWING THE LINK</div>
          <a v-motion-pop-visible href=""><div class="text-[#FAFF00] text-xl lg:text-4xl mt-2" style="text-shadow: #FAFF00 5px 0 15px;">ROOBET.COM/MYSTICBERT!</div></a>
        </div>
        <div class="flex justify-start lg:px-44 leading-10 mt-8 lg:mt-14 uppercase px-8">
          <div class="text-white" v-motion-slide-visible-left>
            DISCLAIMER:
            <br>Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are playing. This helps prevent leaderboard abuse:
            <br><br>Games with an RTP of 97% or less will contribute 100% of the amount wagered to the leaderboard.
            <br>Games with an RTP above 97% will contribute 50% of the amount wagered to the leaderboard.
            <br>Games with an RTP of 98% and above will contribute 10% of the amount wagered to the leaderboard.
          </div>
          
        </div>
    </div>
  </section>

  <ChallengesSection/>
</template>

<script>
import { defineComponent } from 'vue';
import LeaderboardSection from '@/sections/LeaderboardSection.vue';
import ChallengesSection from '@/sections/ChallengesSection.vue';

export default defineComponent({
  components: {
      LeaderboardSection,
      ChallengesSection
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
  },
});
</script>

<style scope>
body {
  background-color: #110011;
}
.page-bg {
  background-image: url('../assets/Big-M-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
